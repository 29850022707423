"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uniqueFields = void 0;
const zod_1 = require("zod");
function uniqueFields(...keys) {
    return function (rows, ctx) {
        keys.forEach(key => {
            const ids = rows.map(row => row[key]).filter(Boolean);
            rows.forEach((row, index) => {
                const value = row[key];
                if (!value)
                    return;
                if (ids.filter(id => id === value).length === 1)
                    return;
                ctx.addIssue({
                    code: zod_1.z.ZodIssueCode.custom,
                    message: `Duplicate value found: ${row[key]}`,
                    path: [index, key],
                });
            });
            return ctx;
        });
    };
}
exports.uniqueFields = uniqueFields;
