import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Spinner = styled.span`
  & > span {
    animation: sk-bouncedelay 1000ms infinite ease both;
  }

  & .bounce1 {
    animation-delay: -300ms;
  }

  & .bounce2 {
    animation-delay: -150ms;
  }

  & .bounce3 {
    animation-delay: 0ms;
  }

  @keyframes sk-bouncedelay {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;

function LoadingIndicator() {
  return (
    <Spinner>
      <span className="bounce1">.</span>
      <span className="bounce2">.</span>
      <span className="bounce3">.</span>
    </Spinner>
  );
}

LoadingIndicator.defaultProps = {};

LoadingIndicator.propTypes = {
  children: PropTypes.any,
};

export default LoadingIndicator;
