import React from 'react';
import { Helmet } from 'react-helmet';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import UsersPage from 'src/pages/UsersPage';
import DataValidationPage from 'src/pages/DataValidationPage';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { createGlobalStyle } from 'styled-components';
import { Router, Redirect } from '@reach/router';

const Stylesheet = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }
`;

const queryClient = new QueryClient();

function getPageTitle() {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  return environment === 'development' || environment === 'staging' ? `[${environment}]` : '';
}

function App() {
  return (
    <ErrorBoundary>
      <Stylesheet />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getPageTitle()} FundFilter Admin</title>
        <link rel="canonical" href="https://admin.fundfilter.com" />
      </Helmet>

      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Router
          className="Router"
          css={`
            display: contents;
          `}
        >
          <Redirect from="/" to="/users" />
          <UsersPage path="/users" />
          <DataValidationPage path="/validations" />
        </Router>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
