import React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTable, useSortBy } from 'react-table';
import { format } from 'date-fns';
import ACCOUNT_TYPES from '@fundfilter/core/src/types/account-types';
import userAccountType from '@fundfilter/core/src/users/account-type';

export function sortAccountTypes(a, b) {
  return a.original.accountType < b.original.accountType ? -1 : 1;
}

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid black;

  border-spacing: 0;
  border: 1px solid black;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
`;

const Th = styled.th`
  text-align: left;
`;

const Td = styled.td`
  text-align: left;
`;

function UsersTable({ users, onUpdateUserData }) {
  const data = useMemo(() => {
    return users.map(user => {
      return { id: user.userId, ...user };
    });
  }, [users]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Account Type',
        id: 'accountType',
        sortType: sortAccountTypes,
        accessor: d => {
          const { userId, accountType } = d;

          return (
            <span
              css={`
                &::before {
                  content: '● ';
                  color: ${userAccountType({ accountType }).isFree ? 'springgreen' : 'steelblue'};
                }
              `}
            >
              <select
                onChange={event =>
                  onUpdateUserData(userId, {
                    accountType: event.target.value,
                  })
                }
                value={accountType}
              >
                {ACCOUNT_TYPES.ALL.map(TYPE => {
                  return (
                    <option key={TYPE} value={TYPE}>
                      {TYPE}
                    </option>
                  );
                })}
              </select>
            </span>
          );
        },
      },
      {
        Header: 'Trial Expiry',
        accessor: ({ trialExpiresAt, accountType }) => {
          const value = trialExpiresAt ? format(trialExpiresAt.toDate(), 'yyyy/MM/dd') : null;

          if (!accountType) return trialExpiresAt;

          const { isFree, isExpired } = userAccountType({ accountType });

          try {
            return value ? (
              <span
                css={`
                  color: ${isFree && isExpired ? 'red' : 'currentColor'};
                `}
              >
                {value}
              </span>
            ) : (
              '—'
            );
          } catch {
            return trialExpiresAt;
          }
        },
      },
      {
        Header: 'Educational Account',
        accessor: ({ educationalAccount }) => {
          const value = educationalAccount ? format(educationalAccount.toDate(), 'yyyy/MM/dd') : null;

          return value ? <span>{value}</span> : '—';
        },
      },
      {
        Header: 'Stripe',
        id: 'stripeCustomerId',
        accessor: d => {
          return <a href={`https://dashboard.stripe.com/customers/${d.stripeCustomerId}`}>View in stripe</a>;
        },
      },
      {
        Header: 'Role',
        accessor: d => {
          const { userId, role = '' } = d;

          return (
            <span>
              <select
                onChange={event =>
                  onUpdateUserData(userId, {
                    role: event.target.value,
                  })
                }
                value={role}
                css={`
                  color: ${role === 'admin' ? 'tomato' : 'lightgray'};
                `}
              >
                <option value={'admin'}>Admin</option>
                <option value={''}>Default</option>
              </select>
            </span>
          );
        },
      },
      {
        Header: 'Last Login',
        id: 'lastLogin',
        accessor: ({ lastLogin }) => (lastLogin ? format(new Date(lastLogin), 'yyyy/MM/dd HH:mma') : '—'),
      },
    ],
    [onUpdateUserData]
  );

  const tableInstance = useTable({ columns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => {
            const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={headerGroupKey} {...headerGroupProps}>
                {headerGroup.headers.map(column => {
                  const { key: headerKey, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps());

                  return (
                    <Th key={headerKey} {...headerProps}>
                      {column.render('Header')}
                      <span
                        css={`
                          font-size: 11px;
                        `}
                      >
                        {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                      </span>
                    </Th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            const { key: rowKey, ...rowProps } = row.getRowProps();

            return (
              <tr key={rowKey} {...rowProps} title={row.original.userId}>
                {row.cells.map(cell => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();

                  return (
                    <Td key={cellKey} {...cellProps}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

UsersTable.defaultProps = {
  users: [],
};

UsersTable.propTypes = {
  children: PropTypes.any,
};

export default UsersTable;
