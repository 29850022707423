import React from 'react';
import styled from 'styled-components';

export const ErrorLayout = styled.div`
  display: grid;
  justify-content: center;

  section {
    padding: 2em;
    max-width: 30rem;
    line-height: 1.5;
  }

  .error-message {
    padding: 1em;
    background-color: #fcebeb;
    color: #532421;
    font-family: monospace;
    font-size: 0.875em;
  }
`;

export function DefaultFallback({ error, children }) {
  return (
    <ErrorLayout>
      <section>
        {children ? (
          children
        ) : (
          <>
            <h2>🤔 Something went wrong</h2>
            <div className="error-message">{error.message}</div>
            <p>
              This may be an intermittent issue. You can try:
              <ul>
                <li>
                  <a href={window.location.pathname}>Refreshing this page</a>
                </li>
                <li>
                  <a href="/">Returning to the homepage</a>
                </li>
              </ul>
            </p>
            <p>
              If the problem persists please consider{' '}
              <a href="https://github.com/altman-ai/fund-filter/issues/new?assignees=&labels=&projects=&template=custom.md&title=%5BPage+Name%2FPanel+Name%5D+Brief+problem+title">
                filing a bug report
              </a>
            </p>
          </>
        )}
      </section>
    </ErrorLayout>
  );
}

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { caughtError: null };
  }

  static getDerivedStateFromError(error) {
    return { caughtError: error };
  }

  componentDidCatch(error, _info) {
    console.error(error);
  }

  render() {
    const { caughtError } = this.state;
    const { children, fallback } = this.props;

    if (!caughtError) return children;
    if (!fallback) return <DefaultFallback error={caughtError} />;
    if (typeof fallback === 'function') return fallback({ error: caughtError });
    return fallback;
  }
}
