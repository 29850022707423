import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import createPersistedState from 'use-persisted-state';
import { auth } from 'src/config/firebase';
import filterApi from 'src/services/filter-api';
import authentication from 'src/services/authentication';
import AdminLoginPage from 'src/pages/AdminLoginPage';
import ErrorBoundary from 'src/components/ErrorBoundary';
import Button from 'src/components/Button';
import NavLink from 'src/components/NavLink';
import Version from 'src/components/Version';

const usePersistedState = createPersistedState('currentUser');

function AdminTemplate({ children, maxContentWidth = '60rem' }) {
  const [currentUser, setCurrentUser] = usePersistedState(null);
  const [isLoginSubmitted, setIsLoginSubmitted] = useState(false);

  useEffect(
    function () {
      if (!isLoginSubmitted) return;

      const unsubscribe = auth.onAuthStateChanged(async function (authUser) {
        if (authUser === null) {
          return setCurrentUser(null);
        }

        const dbUser = await filterApi.getUser(authUser.uid);
        const isAuthorized = dbUser.role === 'admin';
        if (isAuthorized) {
          setCurrentUser(dbUser.email);
        }
      });

      return function cleanup() {
        unsubscribe();
      };
    },
    [isLoginSubmitted, setCurrentUser]
  );

  if (!currentUser) return <AdminLoginPage onSubmit={() => setIsLoginSubmitted(true)} />;
  return (
    <div
      css={`
        display: grid;
        height: 100%;
        grid-template-rows: auto 1fr auto;
      `}
    >
      <header
        css={`
          padding: 1em 2em;
          color: black;
          border-bottom: 1px solid #e0e0e0;
          background-color: #ebeff1;
          background-image: var(--bg-gradient);
        `}
      >
        <div
          css={`
            margin-inline: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1em;
            max-width: 60rem;
          `}
        >
          <nav
            css={`
              display: flex;
              gap: 1em;
            `}
          >
            <NavLink to="/users">Users</NavLink>
            <NavLink to="/validations">Data Validation</NavLink>
          </nav>
          <div
            css={`
              display: flex;
              align-items: center;
              gap: 1em;
            `}
          >
            <span>{currentUser}</span>
            <Button
              size="xs"
              onClick={async () => {
                await authentication.signOut();
                setCurrentUser(null);
              }}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </header>
      <main>
        <div
          css={`
            margin-inline: auto;
            max-width: ${maxContentWidth};
            padding: 2rem;
          `}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </main>
      <footer
        css={`
          display: flex;
          justify-content: center;
          font-size: 0.75rem;
          color: #999;
        `}
      >
        <p>
          <Version />
        </p>
      </footer>
    </div>
  );
}

AdminTemplate.defaultProps = {};

AdminTemplate.propTypes = {
  children: PropTypes.any,
};

export default AdminTemplate;
