"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fundDynamicCsvSchema = void 0;
const zod_1 = require("zod");
const fields_1 = require("../utils/fields");
const constants_1 = require("../constants");
const row = zod_1.z
    .object({
    rowId: (0, fields_1.number)(),
    altman_fund_id: (0, fields_1.string)({ min: 20 }),
    foia_source_acronym: (0, fields_1.list)(constants_1.foiaSourceAcronyms),
    source_type: (0, fields_1.list)(['LP', 'GP']),
    pei_source_id: (0, fields_1.string)({ type: zod_1.z.string().startsWith('institution:') }),
    end_of_quarter_date: (0, fields_1.date)(),
    commitment_amount: (0, fields_1.number)({ allowEmpty: true }),
    commitment_amount_currency: (0, fields_1.list)(constants_1.currencyCodes, { allowEmpty: true }),
    called_amount: (0, fields_1.number)({ allowEmpty: true }),
    called_amount_currency: (0, fields_1.list)(constants_1.currencyCodes, { allowEmpty: true }),
    distributed_amount: (0, fields_1.number)({ allowEmpty: true }),
    distributed_amount_currency: (0, fields_1.list)(constants_1.currencyCodes, { allowEmpty: true }),
    remaining_value: (0, fields_1.number)({ allowEmpty: true }),
    remaining_value_currency: (0, fields_1.list)(constants_1.currencyCodes, { allowEmpty: true }),
    irr: (0, fields_1.number)({ allowEmpty: true }),
})
    .superRefine((row, ctx) => {
    Object.keys(row)
        .filter(key => key.endsWith('_currency'))
        .forEach(currencyField => {
        const amountField = currencyField.replace('_currency', '');
        if (zod_1.z.literal('').safeParse(row[amountField]).success)
            return;
        if ((0, fields_1.list)(constants_1.currencyCodes).safeParse(row[currencyField]).success)
            return;
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: `Invalid value: "${row[currencyField]}"`,
            path: [currencyField],
        });
    });
})
    .superRefine((row, ctx) => {
    const currencyFields = Object.keys(row).filter(key => key.endsWith('_currency'));
    const rowCurrencies = new Set(currencyFields.map(f => row[f]).filter(Boolean));
    if (rowCurrencies.size < 2)
        return;
    ctx.addIssue({
        code: zod_1.z.ZodIssueCode.custom,
        message: `Row contains mixed currencies: "${[...rowCurrencies].join(',')}"`,
        path: [],
    });
});
exports.fundDynamicCsvSchema = {
    name: 'fund_dynamic',
    row: row,
    collection: zod_1.z.array(zod_1.z.any()).superRefine(function (rows, ctx) {
        rows.forEach((row, index) => {
            if (row.source_type !== 'GP')
                return;
            const matchingRows = rows.filter(r => {
                if (r.source_type !== 'GP')
                    return;
                return r.altman_fund_id === row.altman_fund_id && r.pei_source_id !== row.pei_source_id;
            });
            if (matchingRows.length < 2)
                return;
            const { altman_fund_id, source_type, pei_source_id } = row;
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: `Only a single GP source is allowed for each fund`,
                path: [index, [altman_fund_id, source_type, pei_source_id].join('_')],
            });
        });
    }),
};
