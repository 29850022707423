import { db } from 'src/config/firebase';

const dbUsers = db.collection('users');

async function getUser(userId) {
  const doc = await dbUsers.doc(userId).get();
  return await doc.data();
}

async function getUsers() {
  const docs = (await dbUsers.get()).docs;
  return await docs.map(d => d.data());
}

async function updateUser(data) {
  const { userId } = data;
  await dbUsers.doc(userId).update(data);

  return data;
}

const filterApi = {
  getUser,
  getUsers,
  updateUser,
};

export default filterApi;
