"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currencyCodes = void 0;
exports.currencyCodes = [
    'AED',
    'AOA',
    'AUD',
    'AZN',
    'BAM',
    'BGN',
    'BHD',
    'BRL',
    'BSD',
    'BWP',
    'CAD',
    'CHF',
    'CLP',
    'CNY',
    'COP',
    'CZK',
    'DEM',
    'DKK',
    'EGP',
    'ETB',
    'EUR',
    'GBP',
    'GEL',
    'GHS',
    'HKD',
    'HRK',
    'HUF',
    'IDR',
    'ILS',
    'INR',
    'ISK',
    'JPY',
    'KES',
    'KRW',
    'KWD',
    'LRD',
    'LVL',
    'MAD',
    'MUR',
    'MVR',
    'MXN',
    'MYR',
    'NAD',
    'NGN',
    'NOK',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PHP',
    'PLN',
    'QAR',
    'RON',
    'RUB',
    'SAR',
    'SEK',
    'SGD',
    'SLL',
    'THB',
    'TND',
    'TTD',
    'TWD',
    'UAH',
    'UGX',
    'USD',
    'XOF',
    'ZAR',
    'ZWD',
];
