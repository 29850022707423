import { parse as csvParse } from 'papaparse';

export function parse(contents, { header = true, skipEmptyLines = true, worker = true, ...options } = {}) {
  const csvOptions = { header, skipEmptyLines, worker, ...options };

  return new Promise((resolve, reject) => {
    csvParse(contents, {
      ...csvOptions,
      complete: resolve,
      error: reject,
    });
  });
}
