import React from 'react';
import pkg from '../../package.json';

export default function Version() {
  const semver = pkg.version;
  const build = process.env.REACT_APP_VERSION || process.env.NODE_ENV;
  const version = [semver, build].filter(Boolean).join('-');

  return (
    <span
      css={`
        font-size: 0.75rem;
        color: #9097a4;
      `}
    >
      version: {version}
    </span>
  );
}
