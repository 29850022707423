"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.date = exports.number = exports.string = exports.list = void 0;
const zod_1 = require("zod");
function list(values, { allowEmpty = false } = {}) {
    return zod_1.z.any().superRefine((val, ctx) => {
        if (allowEmpty && val === '')
            return;
        if (values.includes(val))
            return;
        return ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: `Invalid value: "${val}"`,
        });
    });
}
exports.list = list;
function string({ type = zod_1.z.string(), allowEmpty = false, min, max } = {}) {
    if (min)
        type = type.min(min);
    if (max)
        type = type.max(max);
    const fieldType = allowEmpty ? zod_1.z.literal('').or(type) : type;
    return zod_1.z.preprocess((val) => val === null || val === void 0 ? void 0 : val.trim(), fieldType);
}
exports.string = string;
function number({ type = zod_1.z.coerce.number().finite(), allowEmpty = false, min, max, } = {}) {
    if (min)
        type = type.gte(min);
    if (max)
        type = type.lte(max);
    return allowEmpty ? zod_1.z.literal('').or(type) : type;
}
exports.number = number;
function date({ type = zod_1.z.string(), allowEmpty = false, min, max } = {}) {
    if (min)
        type = type.min(min);
    if (max)
        type = type.max(max);
    const formatRegex = new RegExp('(19|20)[0-9]{2}-(03-31|06-30|09-30|12-31)');
    const formatType = type.regex(formatRegex);
    return allowEmpty ? zod_1.z.literal('').or(formatType) : formatType;
}
exports.date = date;
