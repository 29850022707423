import React from 'react';
import { useEffect } from 'react';
import DataTable from 'src/components/DataTable';
import { FaFileCsv } from 'react-icons/fa';

function SectionHeader({ input, results }) {
  const tagColor = results.count ? '#dc3568' : '#31c89e';
  const tagText = results.count ? 'Failed' : 'Passed';

  return (
    <header>
      <h4>Results</h4>
      <p
        css={`
          display: flex;
          align-items: center;
          gap: 0.5em;
        `}
      >
        <span
          style={{
            padding: '0.375em 0.5em',
            backgroundColor: tagColor,
            fontSize: '0.675em',
            color: 'white',
          }}
        >
          {tagText}
        </span>{' '}
        {input.file.name}
      </p>
    </header>
  );
}

function Results({ results, input }) {
  if (!results.count) return null;

  return (
    <div>
      <DataTable
        data={results.rawData}
        total={results.count}
        columns={[
          {
            Header: 'Row #',
            accessor: 'rowId',
          },
          {
            Header: 'Column',
            accessor: 'field',
          },
          {
            Header: 'Error',
            accessor: 'message',
          },
        ]}
      />

      {results.csvData && (
        <div>
          <span
            css={`
              display: flex;
              align-items: center;
              gap: 0.25em;
            `}
          >
            <FaFileCsv color="blue" />
            <a
              href={'data:application/octet-stream,' + encodeURIComponent(results.csvData)}
              download={'validation-results-' + input.file.name}
            >
              Download results
            </a>
          </span>
        </div>
      )}
    </div>
  );
}

export default function ValidationResults({ input, results, error }) {
  useEffect(() => {
    if (error) throw new Error(error);
  }, [error]);

  if (results.count === null) return null;
  return (
    <section>
      <SectionHeader input={input} results={results} />
      <Results input={input} results={results} />
    </section>
  );
}
