import React from 'react';
import { useEffect } from 'react';
import { BsCheckSquareFill } from 'react-icons/bs';
import FieldWithError from 'src/components/FieldWithError';
import LoadingIndicator from 'src/components/LoadingIndicator';
import Button from 'src/components/Button';

export default function Form({ state: input, error, isLoading, onSubmit, onChangeFileInput }) {
  useEffect(() => {
    if (error) throw new Error(error);
  }, [error]);

  async function handleSubmit(event) {
    event.preventDefault();
    onSubmit(event);
  }

  const formValidations = {
    file: {
      message: 'Invalid file type. Please upload a CSV file.',
      field: 'file',
      isError: input.file.name === null ? false : input.file.type !== 'text/csv',
    },
    schema: {
      field: 'schema',
      message:
        'Unknown schema. File names should start with the schema name. Currently only altman_fund_static and altman_fund_dynamic are supported.',
      isError: input.file.name === null ? false : input.schema.name === 'unknown',
    },
  };

  const isFormValid = Object.values(formValidations).every(validation => !validation.isError);
  const isFormComplete = input.schema.name && input.file.name;

  return (
    <form
      onSubmit={handleSubmit}
      css={`
        display: grid;
        max-width: 20rem;
        padding: 20px;
        border: 1px solid #ccc;
        gap: 20px;
      `}
    >
      <FieldWithError error={formValidations.file.isError && formValidations.file.message}>
        <input onChange={onChangeFileInput} type="file" disabled={isLoading} />
      </FieldWithError>
      <FieldWithError error={formValidations.schema.isError && formValidations.schema.message}>
        <p
          css={`
            display: flex;
            align-items: center;
            gap: 0.5em;
          `}
        >
          {input.schema.name && input.schema.name !== 'unknown' && <BsCheckSquareFill color="#31c89e" />}Schema:{' '}
          {input.schema.name}
        </p>
      </FieldWithError>
      <Button type="submit" disabled={isLoading || !isFormValid || !isFormComplete}>
        {isLoading ? (
          <span>
            Validating
            <LoadingIndicator />
          </span>
        ) : (
          <span>Validate</span>
        )}
      </Button>
    </form>
  );
}
