import { auth, persistence } from 'src/config/firebase';

async function signIn({ email, password }) {
  await auth.setPersistence(persistence.LOCAL);

  const { user } = await auth.signInWithEmailAndPassword(email, password);
  return user;
}

async function signOut() {
  await auth.signOut();
}

function getCurrentUser() {
  if (!auth.currentUser) return null;

  const { email, emailVerified, uid: userId, displayName } = auth.currentUser;
  return { email, emailVerified, userId, displayName };
}

function isAuthenticated() {
  return !!getCurrentUser();
}

export const authentication = {
  signIn,
  signOut,
  getCurrentUser,
  isAuthenticated,
};

export default authentication;
