import { useReducer } from 'react';
import { getSchema } from '@fundfilter/data-validations';

const ACTIONS = {
  RESET: 'RESET',
  SET_LOADING: 'SET_LOADING',
  SET_INPUT_ERROR: 'SET_INPUT_ERROR',
  SET_INPUT_FILE: 'SET_INPUT_FILE',
  SET_RESULTS_SUCCESS: 'SET_RESULTS_SUCCESS',
  SET_RESULTS_ERROR: 'SET_RESULTS_ERROR',
};

const STATUSES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const initialState = {
  input: {
    file: { name: null },
    schema: { name: null },
    error: null,
  },
  results: {
    rawData: null,
    csvData: null,
    count: null,
    error: null,
  },
  status: STATUSES.IDLE,
  isIdle: true,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

function getStatus(status) {
  if (status === STATUSES.IDLE) {
    return {
      status,
      isIdle: true,
      isLoading: false,
      isSuccess: false,
      isError: false,
    };
  }

  if (status === STATUSES.LOADING) {
    return {
      status,
      isIdle: false,
      isLoading: true,
      isSuccess: false,
      isError: false,
    };
  }

  if (status === STATUSES.SUCCESS) {
    return {
      status,
      isIdle: false,
      isLoading: false,
      isSuccess: true,
      isError: false,
    };
  }

  if (status === STATUSES.ERROR) {
    return {
      status,
      isIdle: false,
      isLoading: false,
      isSuccess: false,
      isError: true,
    };
  }
}

function convertToCSVString(data) {
  const header = Object.keys(data[0]).join(',');
  const rows = data.map(row => {
    return Object.values(row).join(',');
  });
  return [header, ...rows].join('\n');
}

function reducer(state, action) {
  if (action.type === ACTIONS.RESET) {
    return initialState;
  }

  if (action.type === ACTIONS.SET_LOADING) {
    return {
      ...state,
      ...getStatus(STATUSES.LOADING),
    };
  }

  if (action.type === ACTIONS.SET_INPUT_FILE) {
    const { file, schema } = action.payload;

    return {
      ...state,
      input: { file, schema, error: null },
      ...getStatus(STATUSES.IDLE),
    };
  }

  if (action.type === ACTIONS.SET_INPUT_ERROR) {
    return {
      ...state,
      input: { ...state.input, error: action.payload },
      ...getStatus(STATUSES.ERROR),
    };
  }

  if (action.type === ACTIONS.SET_RESULTS_SUCCESS) {
    const results = action.payload;

    const count = results.length;
    const rawData = count > 200 ? results.slice(0, 10) : results;
    const csvData = count > 10 ? convertToCSVString(results) : null;

    return {
      ...state,
      results: { rawData, csvData, count, error: null },
      ...getStatus(STATUSES.SUCCESS),
    };
  }

  if (action.type === ACTIONS.SET_RESULTS_ERROR) {
    return {
      ...state,
      results: { ...state.results, error: action.payload },
      ...getStatus(STATUSES.ERROR),
    };
  }
}

export default function useDataValidationState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  function reset() {
    dispatch({ type: ACTIONS.RESET });
  }

  function setLoading() {
    dispatch({ type: ACTIONS.SET_LOADING });
  }

  function setInputFile(file) {
    try {
      const schema = getSchema(file.name);
      dispatch({ type: ACTIONS.SET_INPUT_FILE, payload: { file, schema } });
    } catch (error) {
      dispatch({ type: ACTIONS.SET_INPUT_FILE, payload: { file, schema: { name: 'unknown' } } });
    }
  }

  function setResultsSuccess(results) {
    dispatch({ type: ACTIONS.SET_RESULTS_SUCCESS, payload: results });
  }

  function setResultsError(error) {
    dispatch({ type: ACTIONS.SET_RESULTS_ERROR, payload: error.message });
  }

  return {
    ...state,
    reset,
    setLoading,
    setInputFile,
    setResultsSuccess,
    setResultsError,
  };
}
