import React from 'react';
import { Link } from '@reach/router';

function NavLink(props) {
  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        return {
          style: {
            fontWeight: isCurrent ? 'bold' : 'normal',
            textDecoration: isCurrent ? 'none' : 'underlined',
            color: 'currentColor',
          },
        };
      }}
    />
  );
}

export default NavLink;
