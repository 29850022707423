import React from 'react';
import styled from 'styled-components';
import { BsExclamationDiamondFill } from 'react-icons/bs';

const Error = styled.p`
  font-size: 0.85em;
  color: #dc3568;
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

function FieldWithError({ children, error }) {
  return (
    <div>
      {children}
      {error && (
        <Error>
          <span width="1em">
            <BsExclamationDiamondFill />
          </span>{' '}
          {error}
        </Error>
      )}
    </div>
  );
}

export default FieldWithError;
