import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { validate } from '@fundfilter/data-validations';
import AdminTemplate from 'src/templates/AdminTemplate';
import { parse } from 'src/utils/csv';
import { read } from 'src/utils/file';
import ErrorBoundary from 'src/components/ErrorBoundary';
import useDataValidationState from './useDataValidationState';
import ValidationResults from './ValidationResults';
import Form from './Form';

function DataValidationPage() {
  const { input, results, reset, setLoading, setInputFile, setResultsSuccess, setResultsError, isLoading } =
    useDataValidationState();

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading();

    try {
      const content = await read(input.file);
      const { data } = await parse(content);
      const validationResults = validate(data, input.schema);
      setResultsSuccess(validationResults);
    } catch (error) {
      console.error(error);
      setResultsError(error);
    }
  }

  function handleChangeFileInput(event) {
    reset();
    const file = event.target.files[0];
    setInputFile(file);
  }

  useEffect(() => {
    if (!input.error && !results.error) return;
    throw new Error(input.error || results.error);
  }, [input.error, results.error]);

  return (
    <AdminTemplate>
      <ErrorBoundary>
        <Form
          state={input}
          error={input.error}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onChangeFileInput={handleChangeFileInput}
        />
      </ErrorBoundary>

      <ErrorBoundary fallback={<h1>FOO!</h1>}>
        <ValidationResults input={input} results={results} isLoading={isLoading} error={results.error} />
      </ErrorBoundary>
    </AdminTemplate>
  );
}

DataValidationPage.defaultProps = {};

DataValidationPage.propTypes = {
  children: PropTypes.any,
};

export default DataValidationPage;
