export function read(file) {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = reject;
    fileReader.readAsText(file);
  });
}
