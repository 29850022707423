import styled from 'styled-components';

const sizes = {
  xs: '0.625em',
  sm: '0.875em',
  md: '1em',
  lg: '1.25em',
};

const Button = styled.button`
  padding: 0.75em 1em;
  color: white;
  background-color: #142337;
  border: 2px solid #142337;
  border-radius: 3px;
  font-size: ${p => sizes[p.size || 'md']};
  cursor: pointer;

  &[disabled] {
    color: #999;
    background-color: #ccc;
    border-color: #ccc;
    cursor: initial;
  }
`;

export default Button;
