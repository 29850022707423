import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import * as serviceWorker from './serviceWorker';

console.info('VERSION:', process.env.REACT_APP_VERSION);

function render(Component) {
  // 👇This may break in future React releases
  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(
    <Component />,

    document.getElementById('root')
  );
}

render(App);

if (module.hot) {
  module.hot.accept('./components/app', () => {
    const NextApp = require('./components/app').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
