import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import AdminTemplate from 'src/templates/AdminTemplate';
import filterApi from 'src/services/filter-api';
import LoadingIndicator from 'src/components/LoadingIndicator';
import ErrorBoundary from 'src/components/ErrorBoundary';
import Table from './Table';

function UsersPage() {
  const [isWorking, setIsWorking] = useState();

  const queryClient = useQueryClient();

  const { isLoading, isError, data, error } = useQuery('users', filterApi.getUsers);

  const mutation = useMutation(filterApi.updateUser, {
    onMutate() {
      setIsWorking(true);
    },
    onSettled() {
      queryClient.invalidateQueries('users');
      setTimeout(() => setIsWorking(false), 500);
    },
  });

  function handleUpdateUserData(userId, newData) {
    mutation.mutate({ ...newData, userId });
  }

  return (
    <AdminTemplate maxContentWidth="unset">
      <ErrorBoundary>
        <div
          css={`
            display: grid;
            justify-content: center;
            min-width: 60rem;
          `}
        >
          <div>{(isLoading || isWorking) && <LoadingIndicator />}&nbsp;</div>
          <Table users={data} onUpdateUserData={handleUpdateUserData} />
          <div>
            {isError && <span>Error: {error.message}</span>}
            &nbsp;
          </div>
        </div>
      </ErrorBoundary>
    </AdminTemplate>
  );
}

UsersPage.defaultProps = {};

UsersPage.propTypes = {
  children: PropTypes.any,
};

export default UsersPage;
