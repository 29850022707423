"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validate = exports.getSchema = void 0;
const schemas = __importStar(require("./schemas"));
function getSchema(file) {
    if (file.includes('altman_fund_static'))
        return schemas.fundStaticCsvSchema;
    if (file.includes('altman_fund_dynamic'))
        return schemas.fundDynamicCsvSchema;
    throw new Error('No schema found');
}
exports.getSchema = getSchema;
function validate(data, schema) {
    const rowIssues = data
        .map((row, index) => {
        const rowId = index + 2;
        const result = schema.row.safeParse(Object.assign({ rowId }, row));
        if (result.success === true)
            return [];
        return result.error.issues.map(issue => {
            return { rowId, field: issue.path.join(','), message: issue.message };
        });
    })
        .flat();
    const collectionResult = schema.collection.safeParse(data);
    if (collectionResult.success === true)
        return rowIssues;
    const collectionIssues = collectionResult.error.issues.map(issue => {
        const [index, field] = issue.path;
        return { rowId: index + 1, field, message: issue.message };
    });
    return [...rowIssues, ...collectionIssues].sort((a, b) => a.rowId - b.rowId);
}
exports.validate = validate;
__exportStar(require("./schemas"), exports);
