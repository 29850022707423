import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import authentication from 'src/services/authentication';
import filterApi from 'src/services/filter-api';
import ErrorBoundary from 'src/components/ErrorBoundary';
import LoadingIndicator from 'src/components/LoadingIndicator';
import Button from 'src/components/Button';
import Version from 'src/components/Version';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  background-image: var(--bg-gradient);
`;

const Form = styled.form`
  display: grid;
  grid-auto-flow: row;
  width: 24rem;
  gap: 0.75rem;
  padding: 3rem;
  background-color: white;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
`;

const Input = styled.input`
  padding: 0.75em 1em;
  border-width: 2px solid currentColor;
`;

function AdminLoginPage({ onSubmit }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isFormComplete = email && password;

  async function handleSubmit(event) {
    event.preventDefault();

    if (!isFormComplete) {
      return setError('You must fill in all fields');
    }

    try {
      setIsLoading(true);
      const user = await authentication.signIn({ email, password });
      const currentUser = await filterApi.getUser(user.uid);

      if (currentUser.role !== 'admin') {
        throw new Error('Only admin users can access this app');
      }

      setEmail('');
      setPassword('');
      onSubmit(currentUser);
    } catch (err) {
      console.error(err);
      setError(err.message);
      onSubmit({ error: err.message });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Layout>
      <div
        css={`
          width: 24rem;
        `}
      ></div>
      <ErrorBoundary>
        <Form>
          <h2>Please sign in</h2>
          <Input placeholder="email" onChange={event => setEmail(event.target.value)} />
          <Input placeholder="password" type="password" onChange={event => setPassword(event.target.value)} />
          <Button type="submit" onClick={handleSubmit} disabled={!isFormComplete}>
            {isLoading ? (
              <span>
                Please wait
                <LoadingIndicator />
              </span>
            ) : (
              'Sign In'
            )}
          </Button>
          <span
            css={`
              font-size: 0.875em;
              color: #dc3568;
            `}
          >
            {error}
          </span>
          <p
            css={`
              text-align: center;
            `}
          >
            <Version />
          </p>
        </Form>
      </ErrorBoundary>
    </Layout>
  );
}

AdminLoginPage.defaultProps = {
  onSubmit: () => {},
};

AdminLoginPage.propTypes = {
  onSubmit: PropTypes.func,
};

export default AdminLoginPage;
